.mp-date-range-picker-main {
    position: relative;
}

.mp-date-range-picker {
    border-radius: 2px;
    padding: 5px;
    position: absolute;
    z-index: 9999;
    box-shadow: 0 .3rem 1rem rgba(0,0,0, .8);
    color: black;
    background: #fff;
    top: 30px;
    right: 20px;
}

.mp-date-range-picker-tools {
    padding: 5px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin: 5px;
}

.mp-date-range-picker-small-icon {
    transition: scale .2s;
    cursor: pointer;
}

.mp-date-range-picker-small-icon:hover {
    transform: scale(1.2);
}

.mp-date-range-picker-cover {
    background-color: rgba(0, 0, 0, .3);
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 10;
}

.mp-date-range-picker-btn-apply,
.mp-date-range-picker-btn-reset {
    cursor: pointer;
    transition: all .2s;
    outline: none !important;
    font-weight: 300;
    margin: 0 5px;
}

.mp-date-range-picker-btn-apply:hover {
    transform: translateY(-2px);
    background-color: #fff !important;
    border: 1px solid #3d91ff !important;
    color: black !important;
    box-shadow: 0 .4rem .8rem rgba(0,0,0, .3);
}

.mp-date-range-picker-btn-reset:hover {
    background-color: #fff !important;
    border: 1px solid #ff3d61 !important;
    color: black !important;
    box-shadow: 0 .4rem .8rem rgba(0,0,0, .3);
}

.mp-date-range-picker-btn-apply:focus,
.mp-date-range-picker-btn-reset:focus {
    color: #333 !important;
    outline: none !important;
    background-color: #fff !important;
    border: 1px solid #ccc !important;
}

.mp-date-range-picker-btn-apply:focus:hover {
    background-color: #fff !important;
    border: 1px solid #3d91ff !important;
    color: black !important;
    box-shadow: 0 .4rem .8rem rgba(0,0,0, .3);
}

.mp-date-range-picker-btn-apply:focus:hover {
    background-color: #fff !important;
    border: 1px solid #ff3d61 !important;
    color: black !important;
    box-shadow: 0 .4rem .8rem rgba(0,0,0, .3);
}

.mp-date-range-picker-btn-apply:active {
    transform: translateY(1px);
    background-color: #fff !important;
    border: 1px solid #3d91ff !important;
    color: black !important;
    box-shadow: 0 .2rem .8rem rgba(0,0,0, .2);
}

.mp-date-range-picker-btn-reset:active {
    transform: translateY(1px);
    background-color: #fff !important;
    border: 1px solid #ff3d61 !important;
    color: black !important;
    box-shadow: 0 .2rem .8rem rgba(0,0,0, .2);
}
