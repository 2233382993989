.premium_sku_dashboard_wrapper {
  padding: 20px 0;
}

.debug_toggle_container {
  margin: 10px 0;
}

.debug_toggle_container > div {
  display: inline-block !important;
  margin-left: 5px;
}

.premium_sku_dashboard_wrapper .action_button_wrapper {
  margin: 10px 0 30px 0;
}

.premium_sku_dashboard_wrapper .def_act_btn {
  margin-left: 10px;
  font-size: 18px;
  cursor: pointer;
}

.premium_sku_dashboard_wrapper .action_button {
  border-radius: 5px;
  margin-right: 10px;
}

.premium_sku_dashboard_wrapper .custom_panel {
  background: transparent;
  border: none;
}

.premium_sku_dashboard_wrapper .test_panel{
  padding: 20px;
}

.premium_sku_dashboard_wrapper .def_comp {
  background: #fff;
  margin: 10px 0;
  border-radius: 10px;
  padding: 10px 0;
  border: 1px solid #ddd;
}

.premium_sku_dashboard_wrapper .def_name {
  font-weight: bold;
  font-size: 12px;
}

.premium_sku_dashboard_wrapper .panel_title {
  padding: 10px 0;
}

.premium_sku_dashboard_wrapper .sub_panel_title {
  padding: 30px;
  font-size: 15px;
  background: #333;
  color: #fff;
  border-radius: 5px;
  margin: 10px 0;
  margin-bottom: 30px;
}

.premium_sku_dashboard_wrapper .table {
  background: #fff;
  margin-bottom: 30px;
}

.premium_sku_dashboard_wrapper .active_instance_table_row td {
  padding: 5px 10px 15px 10px !important;
}

.premium_sku_dashboard_wrapper th {
  padding: 2px 10px !important;
  background: #f5f5f5;
}

.premium_sku_dashboard_wrapper .active_instance_table_row td ol {
  margin-bottom: 0;
  margin-left: 0;
  padding-left: 0;
}

.premium_sku_dashboard_wrapper .json__tree_toggler span {
  color: #777;
}
.premium_sku_dashboard_wrapper .json__tree_toggler {
  margin-left: 0;
  margin-bottom: 0;
  padding-bottom: 0;
  padding-left: 0;
  font-size: 12px;
  width: 135px;
  padding-top: 0;
}

.def_use_modal .modal-dialog {
  margin-top: 85px;
}

.platform_control_label {
  margin-bottom: 12px;
}

.premium_sku_dashboard_wrapper .test_control_label {
  margin-top: 10px;
}

.premium_sku_dashboard_wrapper .test_result_wrapper {
  margin: 15px 0;
}

.premium_sku_dashboard_wrapper .def_comp .checkbox {
  margin: 0;
  display: inline-block;
}

.premium_sku_dashboard_wrapper .use_selected_btn {
  margin: 20px 0 10px 0;
}

.premium_sku_dashboard_wrapper .position_title {
  background: #0C7233;
  padding: 5px 10px;
  color: #fff;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  margin-bottom: 0;
}

.premium_sku_dashboard_wrapper .selected_index {
  background-color: red;
  color: #fff;
  font-size: 12px;
  float: left;
  margin-right: 5px;
  height: 19px;
}

.premium_page_simulator_wrapper {
  background-color: #0E0E0E;
  color: #D5D5D3;
  padding: 10px 10px 10px 10px;
  width: 300px;
  border-radius: 20px;
}

.premium_page_simulator_wrapper strong, .premium_page_attr {
  color: #fff;
}

.premium_page_sku {
  background-color: #4C4C4C;
  padding: 15px 10px;
  border-radius: 10px;
  margin: 5px;
}

.premium_page_sku.selected_sku {
  border: 2px solid #E7B676;
}

.premium_page_attr {
  font-weight: bold;
  text-align: center;
  background-color: #60A1AD;
  border-radius: 10px;
  width: 110px;
  margin: 30px auto 5px auto;
  padding: 3px 7px;
}