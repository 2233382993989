.header {
  display: none;
  color: white;
}

.header__inner {
  background-color: #000;
  display: flex;
  justify-content: space-between;
  padding: .5rem 3rem .5rem 1rem
}

.header__branding {
  color: #fff !important;
  font-family: 'Roboto', sans-serif;
  text-decoration: none !important;
  font-size: 16px;
  display: flex;
  margin-top: 2px;
  align-items: flex-end;
}

.header__login_btn {
  float: right;
  cursor: pointer;
  color: #ccc;
}

.header__login_btn:hover {
  color: #999;
}

.header__display_name {
  margin-right: 35px;
  position: relative;
}

.header__right_menu code {
  font-size: 8px;
  margin-top: -11px;
  right: 0;
  position: absolute;
  padding: 1px 4px;
  border-radius: 0;
}

.header__logout {
  color: #999 !important;
  border: 1px solid #333;
  padding: 6px 8px;
  text-decoration: none !important;
  cursor: pointer;
  border-radius: 5px;
  margin-right: 3px;
}

.header__logout:hover {
  background: #222;
}

.header__right_menu {
  display: flex;
  justify-content: center;
  align-items: center;
}

.header__name {
  display: flex;
  flex-direction: column;
}

.env_warn {
  background: red;
  font-size: 1rem;
}
.env_no_warn {
  background: green;
  font-size: 1rem;
}

@media only screen and (min-width: 768px) {
  .header {
    display: block;
    z-index: 99999;
    position: fixed;
    width: 100%;
  }
}
