.reload__button {
  position: fixed;
  width: 21.4%;
  bottom: 5px;
  left: 5px;
  z-index: 999999999999;
}

.reload__button .btn {
  outline: none !important;
  color: #fff;
}
