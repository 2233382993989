@media only screen and (min-width: 768px) {
    .precalc-ranges {
        margin-top: 15px;
    }

    .precalc-submit {
        margin-top: 15px;
    }

    .precalc-end-num {
        margin-left: 8px;
    }
}

.precalc-results-for-dev {
    margin-top: 50px;
    max-height: 800px;
    overflow: scroll;
}

.precalc-results-success {
    margin-top: 40px;
}

.precalc-results-success-title {
    font-size: 20px;
    font-weight: 300;
    color: #003e18;
}

.precalc-results-success-table {
    max-height: 500px;
    overflow: scroll;
    border: 2px solid #097233;
    padding: 8px;
}

.success-list-row {

}

.precalc-results-failed {
    margin-top: 40px;
}

.precalc-results-failed-title {
    font-size: 20px;
    font-weight: 300;
    color: red;
}

.precalc-results-failed-table {
    max-height: 800px;
    overflow: scroll;
    border: 2px solid red;
    padding: 8px;
}

.precalc-diff-resolver-main {
    border: 2px solid red;
    padding: 8px;
}

.precalc-merge-tool-bar {
    margin: 10px 0;
}

.precalc-merge-reset-buttons {
    float: left;
}

.precalc-merge-reset-btn {
    margin: 0 5px
}

.precalc-merge-navigation-buttons {
    float: right;
}

.precalc-merge-navigate-btn {
    margin: 0 5px
}

.error-list-row {

}

.invalid-file-name-error {
    font-size: 16px;
    color: red;
}

.api-test-submit-button {

}

.precalc-list-manual-update {
    margin-top: 40px;
}

.precalc-list-update-title {
    font-size: 20px;
    font-weight: 300;
    color: red;
}

.precalc-error-list-item {
    margin: 5px 0;
    border: 2px solid #d9534f;
}

.precalc-list-update-instructions {
    font-size: 16px;
    font-weight: 300;
    color: red;
}

.precalc-city-error-list {
    padding: 0;
    max-height: 200px;
    overflow-y: auto;
}

.precalc-version-diff-main::-webkit-scrollbar-track,
.precalc-city-error-list::-webkit-scrollbar-track,
.precalcs-diff-merge-view::-webkit-scrollbar-track,
.precalcs-diff-side-view::-webkit-scrollbar-track {
    border: 1px solid #000;
    padding: 2px 0;
    background-color: #404040;
}

.precalc-version-diff-main::-webkit-scrollbar,
.precalc-city-error-list::-webkit-scrollbar,
.precalcs-diff-merge-view::-webkit-scrollbar,
.precalcs-diff-side-view::-webkit-scrollbar{
    width: 10px;
}

.precalc-version-diff-main::-webkit-scrollbar-thumb,
.precalc-city-error-list::-webkit-scrollbar-thumb,
.precalcs-diff-merge-view::-webkit-scrollbar-thumb,
.precalcs-diff-side-view::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #337ab7;
    border: 1px solid #000;
}

.precalc-version-diff-main::-webkit-scrollbar-thumb:hover,
.precalc-city-error-list::-webkit-scrollbar-thumb:hover,
.precalcs-diff-merge-view::-webkit-scrollbar-thumb:hover,
.precalcs-diff-side-view::-webkit-scrollbar-thumb:hover {
    border-radius: 15px;
    background-color: #337ab7;
}

.copy-single-errors-list {
    display: flex;
    align-items: flex-start;
}

.copy-single-errors-list-text {
    margin-right: 5px;
    font-weight: 500;
    color: red;
}

.copy-success-list, .copy-errors-list, .copy-precalc-new-list {
    display: flex;
    align-items: flex-end;
}

.copy-success-list-text, .copy-errors-list-text, .copy-precalc-new-list-text {
    margin-right: 5px;
    font-weight: 500;
}

.copy-success-list-text {
    color:  #097233;
}

.copy-errors-list-text {
    color: red;
}

.copy-precalc-new-list-text {
    color: red;
}

.success-list-header, .failed-list-header, .precalc-list-update-inst-copy {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.precalcs-diff-side-view {
    max-height: 400px;
    overflow-y: auto;
}

.precalcs-diff-merge-view {
    max-height: 400px;
    overflow-y: auto;
}

.precalcs-diff-side-view-header {
    font-weight: 400;
    font-size: 1.2em;
}

.precalcs-diff-merge-view-header {
    font-weight: 400;
    font-size: 1.2em;
}

.precalc-diff-resolver-main .click-to-remove-icon svg {
    transform: rotate(45deg);
    color: #00C851 !important;
}

.precalc-diff-resolver-main .copy-to-clipboard-container svg {
    color: #4a90e2 !important;
}

.precalc-update-raw-json-main .copy-to-clipboard-container,
.precalc-update-raw-json-main .click-to-remove {
    display: none !important;
}

.precalc-diff-main-success {
    background: #e0f1e4;
    padding: 10px;
}

.precalc-diff-main-error {
    background: #f1e0e0;;
    padding: 10px;
}

.precalc-list-update-operation-title {
    font-size: 20px;
    font-weight: 300;
    color: #003e18;
}

.precalc-list-update-operation-title-success {
    font-weight: 300;
    color: #003e18;
}

.precalc-list-update-operation-title-error {
    font-weight: 300;
    color: red;
}

.precalc-list-update-operation-info {
    font-size: 18px;
    font-weight: 300;
    color: red;
}

.copy-precalc-new-list-on-error {
    display: flex;
    align-items: flex-end;
    justify-content: center;
}


.copy-precalc-new-list-text-on-error {
    font-weight: 300;
    color: red;
}

.copy-precalc-new-list-on-error-btn {
    margin-left: 10px;
}

.update-with-differ-main {
    max-height: 800px;
    overflow: scroll;
    border: 2px solid #097233;
    padding: 8px;
    margin-top: 30px;
}

.update-differ-paste-main {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.update-differ-paste-header {
    font-size: 16px;
    font-weight: 300;
    color: #003e18;
}

.precalc-merge-start-btn {
    margin-top: 5px;
}

.update-row-file-main {
    margin-top: 40px;
    max-height: 800px;
    overflow: scroll;
    border: 2px solid #097233;
    padding: 8px;
}

.row-json-update-btn {
    margin-top: 5px;
}

.precalc-update-raw-tool-bar {
    display: flex;
    justify-content: space-between;
    padding-bottom: 5px;
    align-items: flex-end;
}

.precalc-update-raw-reset-btn {
    margin-left: 5px;
}

.precalc-update-raw-file-name-input {
    margin: 5px 0;
}

.precalc-update-raw-json-main {
    padding: 0 15px;
}

.precalc-update-raw-json-view {
    max-height: 600px !important;
    padding: 0;
}

.precalc-api-response-main-success {
    margin-top: 40px;
    max-height: 800px;
    min-height: 500px;
    overflow: scroll;
    border: 2px solid #097233;
    padding: 15px;
}

.precalc-api-response-main-error {
    margin-top: 40px;
    max-height: 800px;
    overflow: scroll;
    border: 2px solid red;
    padding: 15px;
}

.precalc-api-response-main-success-header {
    font-size: 20px;
    font-weight: 500;
    color: #003e18;
}
.precalc-api-response-main-error-header {
    font-size: 16px;
    font-weight: 300;
    color: red;
}

.precalc-api-response-tools {
    display: flex;
    justify-content: space-between;
    padding-bottom: 10px;
    border-bottom: 1px solid black;
}

.precalc-api-response-tools-time {
    padding-top: 5px;
}

.precalc-api-response-date-picker {
    display: flex;
    align-items: baseline;
}

.precalc-api-response-date-picker-text {
    font-size: 16px;
    font-weight: 400;
    color: #097233;
    margin-right: 5px;
}

.last-update-time {
    color: #2f6da4;;
}

.precalc-api-response-json {
    margin-top: 15px;
}

.precalc-selected-time-table {
    border: 1px solid black;
}

.precalc-selected-time-table-header {
    background: #d5e0e0;
}

.precalc-selected-time-table-header-cell, .precalc-selected-time-table-data-cell {
    font-weight: 500;
    font-size: 16px;
    width: 16.16%;
    text-align: center;
}

.precalc-selected-time-table-data-cell {

}

.precalc-selected-time-table-main-text {
    margin-top: 15px;
    font-size: 16px;
    font-weight: 400;
    color: #003e18;
}

.precalc-selected-time-table-header-row {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
}


.precalc-selected-time-table-copy {
    display: flex;
    align-items: flex-start;
}

.precalc-selected-time-table-copy-text {
    margin-right: 5px;
    font-weight: 500;
    color: #003e18;
}

.global-warning {
    margin-top: 5px !important;
}

.global-warnings {
    margin-top: 40px;
}

.global-warning-header {
    font-size: 18px;
    font-weight: 400;
}

.global-warning-bullet-point {
    font-size: 14px;
    line-height: 1.5;
}

.precalc-confirm-window-body {
    margin-top: 3px;
    border: 2px solid #097233;
}

.precalc-confrim-data-key {
    font-size: 15px;
    font-weight: 700;
}

.cache-clear-btn-main {
    margin-top: 20px;
}

.precalc-no-warning-list-item {
    list-style: none;
    font-size: 15px;
    font-weight: 500;
    padding-left: 5px;
    color: #00722d;
}

.precalc-no-warning-list-item-text {
    margin-top: 6px;
}

.validate-push-reset-btn {
    border: 1px solid #333;
    color: white;
    background-color: #00722d;
}

.validate-push-reset-btn:hover {
    background-color: #005d27;
    color: white;
}

.validate-push-config-btn-main {
    margin-left: 5px;
}

.precalc-version-list-tools {
    display: flex;
    flex-direction: row;
    align-items: baseline;
}

.precalc-version-diff-warning {
    color: red;
    margin-top: 10px;
}

.precalc-version-diff-tools {
    margin: 5px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.precalc-version-diff-main {
    max-height: 800px;
    overflow-y: scroll;
}

.precalc-file-versions-table-main {
    overflow-y: auto;
    max-height: 600px;
}

.precalc-file-versions-table--main thead th {
    position: sticky;
    top: 0;
}

.precalc-file-versions-table-  {
    border-collapse: collapse;
    width: 100%;
}

.precalc-file-versions-table- th, td {
    padding: 8px 16px;
}

.precalc-file-versions-table- th {
    background:#eee;
}

.precalc-valid-data {
    color: darkgreen;
    font-size: 14px;
}

.precalc-invalid_data {
    color: red;
    font-size: 14px;
}



