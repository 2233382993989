.create-button {
  display: inline-block;
  padding: 10px 20px;
  background-color: green;
  color: white;
  text-decoration: none;
  border-radius: 4px;
}

.refresh-button {
  display: inline-block;
  padding: 10px 20px;
  background-color: blue;
  color: white;
  text-decoration: none;
  margin-bottom: 10px;
}

.col-md-3-padding-top {
  padding-top: 20px;
}

.input-row-margin {
  margin-bottom: 5px;
  margin-left: 5px;
}

.small-input-100 {
  width: 100px
}

.small-input-50 {
  width: 60px
}

.small-input-70 {
  width: 70px
}

.small-input-200 {
  width: 200px
}

.small-input-400 {
  width: 400px
}

.small-input-full {
  width: 1300px
}

.small-input-format {
  width: 55px;
}

.small-input-status {
  width: 77px;
}

.small-input-taskid {
  width: 980px
}

.margin-bottom-5 {
  margin-bottom: 5px;
}

.margin-left-5 {
  margin-left: 5px;
}

.margin-right-5 {
  margin-left: 5px;
}

.subtitle-url-button {
  display: inline-block;
  padding: 10px 20px;
  background-color: #c5c5c5;
  color: rgb(0, 0, 0);
  text-decoration: none;
  border-radius: 4px;
  font-size: 12px;
  border: #4a4a4a;
  cursor: pointer;
  margin-left: 5px;
}

.subtitle-url-button:hover {
  color: aliceblue;
  background-color: #0056b3;
}

.rounded-left-checkbox {
  width: 20px; /* Adjust size */
  height: 20px; /* Adjust size */
  cursor: pointer; /* Adds a pointer cursor on hover */
}
