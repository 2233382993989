.transfer__premium {
  margin-top: 15px;
  padding-left: 0;
}

.transfer__premium .userlink__cont a {
  margin-right: 10px;
}

.userlink__cont {
  margin-top: 3px;
  font-weight: normal;
}

.transfer__premium .transfer__button {
  margin-top: 20px;
  margin-right: 10px;
}

.to__col {
  margin-top: 15px;
}

.transfer__premium .alert {
  padding: 3px 10px;
  margin-bottom: 10px;
  border-radius: 2px;
}

.transfer__premium .alert__cont code {
    padding: 2px 4px;
    font-size: 10px;
    color: #fff;
    background-color: #555;
    margin-right: 10px;
    border-radius: 2px;
}

.alert.From code {
  background: #33FFA8;
  color: #000;
}

.alert.To code {
  background: #FF9333;
  color: #fff;
}

.confirmation__section {
    background: #fff;
    padding: 0 0 20px 0px;
    border-radius: 2px;
    border: 1px solid #ddd;
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 5px;
}

.confirmation__section strong {
  font-size: 85%;
  margin-top: 10px;
  display: inline-block;
  color: #555;
}

.confirmation__section_header {
  background: #F2DEDF;
  padding: 10px;
  margin-bottom: 20px;
  color: #a94442;
  font-size: 18px;
}

.final-success {
  padding: 10px !important;
}

.transfer__sts {
  background: #fff;
  padding: 10px;
  border-radius: 2px;
  border: 1px solid #ddd;
  margin-left: 0;
  margin-right: 0;
  margin-bottom: 5px;
}

.transfer__sts ul {
  padding: 0 18px;
  margin-top: 10px;
}

.transfer__sts ul li {
  list-style:square;
}

.transfer__sts .fa-check-circle {
  color: #0D7233;
}

.transfer__sts .fa-exclamation-circle {
  color: red;
}
