.my-table-wrapper {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; /* For smooth scrolling on iOS */
  }

.my-table {
    width: 100%;
    border-collapse: collapse;
}

.my-table th,
.my-table td {
    padding: 8px;
    border: 1px solid #ddd;
}

.my-table th {
    background-color: green;
}

.my-table th:hover {
    background-color: #ddd;
    color: black
}

.my-table td {
    padding: 8px;
}

.my-table tr:hover {
    background-color: #f5f5f5;
}

.header-cell {
    color: white;
    font-weight: bold;
    padding: 8px;
    align-items: center;
}

.cell-content {
    font-weight: bold;
    color: #333;
}

.search-input {
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    width: 200px;
}

.search-input:focus {
    outline: none;
    border-color: #007bff;
}

.pagination {
    margin-top: 10px;
    display: flex;
    justify-content: center;
}

.pagination-button {
    padding: 8px 16px;
    margin-right: 8px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.pagination-button-red {
    padding: 8px 16px;
    margin-right: 8px;
    background-color: #ff0000;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.audio-manifest-change-button {
    padding: 8px 16px;
    margin-right: 8px;
    margin-left: 5px;
    background-color: yellow;
    color: black;
    border: black;
    border-radius: 4px;
    cursor: pointer;
}

.pagination-button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}

.page-info {
    margin-top: 10px;
    font-size: 14px;
    color: #333;
}

.qalbox-form-row {
    display: flex; 
    align-items: center;
}

.qalbox-div-row {
    display: flex;
    align-items: center;
    justify-content: space-between; /* Distribute space between the buttons */
    width: 100%; /* Ensure the container takes the full width of its parent */
}

.qalbox-create-button {
    display: inline-block;
    padding: 10px 20px;
    background-color: green;
    color: white;
    text-decoration: none;
    border-radius: 4px;
  }
  