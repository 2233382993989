.developer__options {
    padding: 15px 0;
}

.devop__item {
    display: inline-block;
    width: 100%;
    margin: 10px 0;
}

.devop__item .title {
    margin-bottom: 2px;
}

.devop__item .detail {
    font-size: 10px;
}

.devop__item .toggle_wrapper {

}
