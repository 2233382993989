.login {
  text-align: center;
}

.login__title {
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
  margin-top: 200px;
}

.login__google_btn {
  background: #db3236;
  border: none;
  box-shadow: none;
  color: #fff;
  text-shadow: none;
  border-radius: 2px;
  padding: 8px;
  width: 230px;
  margin-top: 20px;
  text-align: left;
  outline: none !important;
}

.login__google_btn_icon {
  display: inline;
  margin-left: 5px;
  float: left;
  margin-top: 3px;
}

.login__google_btn_label {
  display: inline-block;
  float: right;
  margin-right: 10px;
}

.login__error_text {
  margin-top: 30px;
}
