.undo__bar {
  position: fixed;
  top: 0;
  width: 550px;
  left: 28%;
  background: #e7f3eb;
  padding: 5px 10px;
  z-index: 9999999;
  margin-top: 3px;
}

.undo__action_btn {
  cursor: pointer;
  font-weight: bold;
  float: right;
  margin-left: 10px;
  color: #0D7233;
}
