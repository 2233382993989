@keyframes shake {
  10%, 90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%, 80% {
    transform: translate3d(2px, 0, 0);
  }

  30%, 50%, 70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%, 60% {
    transform: translate3d(4px, 0, 0);
  }
}

.hijri_json {
  margin-top: 20px;
}

.hijri_section_title {
  margin-bottom: 30px;
  font-size: 15px !important;
  letter-spacing: 1px;
}

.hijri_json_code {
  background: #fff;
  color: #000;
}

.color_box {
  background: #a94442;
  width: 20px;
  height: 20px;
}

.hijri_json_updated_at_msg {
  margin-top: 5px;
}

.hijri_loader {
  font-size: 20px;
}

.hijri-sighting-year-tool-box {
  display: flex;
  width: 95px;
  justify-content: space-between;
}

.hijri-sighting-country-tool-box {
  display: flex;
  width: 110px;
  justify-content: space-between;
}

.hijri-sighting-country-tool-box-add {
  display: flex;
  align-items: baseline;
}

.hijri-sighting-country-select,
.hijri-years-countries-select {
  min-width: 300px;
  margin-right: 5px;
}

.hijri-years-country-select-main {
  display: flex !important;
  align-items: baseline;
}

.hijri-years-countries-select {
  min-width: 300px;
  display: inline-block;
  z-index: 10;
}

.hijri-years-start-date-input {
  max-width: 165px;
}

.hijri-years-start-date-item  {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  padding: 7px 12px;
}

.hijri-sighting-country-year-tool-box {
  display: flex;
  align-items: baseline;
  font-size: 12px;
  min-width: 310px !important;
  justify-content: space-between;
  font-family: sans-serif;
}

.hijri-year-country-header {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
}

.hijri-country-item {
  /*animation: shake 1s ease-in both;*/
  margin: 10px;
  padding: 10px;
  /*animation-delay: .5s;*/
}


