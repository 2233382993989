.dev-opt-account-details, .dev-opt-account-details a {
  color: red;
}

.dev-opt-form .form-group {
  display: flex;
  flex-direction: column;
}

.dev-sub-opt-form {
  display: grid;
  grid-template-columns: repeat(auto-fill, 150px);
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 10px;
}

.dev-sub-opt {
  padding: 10px;
  border-radius: 10px;
  background: #8080803d;
  align-items: center;
  display: flex;
  min-height: 150px;
  min-width: 150px;
  box-shadow: 0 0 5px -1px rgba(0,0,0,0.1);
  opacity: 0.6;
}

.dev-sub-opt:hover {
  color: #0c7233;
  opacity: 1;
}

.dev-sub-opt:active {
  color: #129f45;
  box-shadow: 0 0 5px -1px rgba(0,0,0,0.6);
}

.dev-opt-section-title {
  border-bottom: 2px solid #80808096;
  padding-bottom: 5px;
}

.dev-opt-wrapper .pretty-json-container {
  word-break: break-all;
}

.full-width-button {
  width: 100%;
  background-color: blue;
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
}

.full-width-button:disabled {
  background-color: grey;
  cursor: not-allowed;
}

.loader {
  border: 4px solid #f3f3f3; /* Light grey */
  border-top: 4px solid blue; /* Blue */
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}