.mp-table-main {
    box-shadow: 0 0 2px rgba(0,0,0,.3);
    margin-bottom: 0;
}

.mp-table {
    border-spacing: 0;
    border: 1px solid #ededed;
    margin-bottom: 0 !important;
    min-height: 475px;
    font-size: 14px;
}

.mp-table tbody tr {
    /*background: white;*/
    transition: all .2s;
}

.mp-table tbody tr:hover {
    background: rgba(185, 220, 193, 0.54);
    transform: scale(1.005) translateZ(2px);
}

.mp-table>thead>tr>th>colspan {
    border-bottom: 1px solid #ededed;;
}

.mp-table>thead>tr>td {
    overflow-wrap: break-word;
}

.mp-table>thead>tr>th {
    border-bottom: none;
    border-top: none;
    text-align: center;
    font-weight: 300;
    font-size: 16px;
    background: #0D7233;
    color: #f5f5f5;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.mp-table .mp-table-sort-icon::before {
    position: absolute;
    right: 15px;
    top: 16px;
    content: "";
    width: 0;
    height: 0;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
}

.mp-table .mp-table-sort-icon.sort-asc::before {
    border-bottom: 6px solid white;
}

.mp-table .mp-table-sort-icon.sort-desc::before {
    border-top: 6px solid #ffffff;
}

.mp-table-resize-handle {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    background: black;
    opacity: 0.5;
    width: 3px;
    cursor: col-resize !important;
    transition: all .2s;
}

.mp-table-resize-handle:hover {
    opacity: 1;
    width: 6px;
}

.mp-table-row-selected {
    background: #b3ffaf7a !important;
}

.mp-table-pagination {
    background: #0D7233;
    padding: 20px 10px;
    display: flex;
    justify-content: flex-start;
    font-weight: 300;
}

.mp-table-pagination span {
    color: white;
}

.mp-table-filter-applied {
    border: 2px solid darkred !important;
}

.mp-table input,
.mp-table select {
    color: black;
}

/* FORM INPUT START */
.mp-table-input {
    font-size: 1.5rem;
    padding: .5rem;
    margin: .5rem auto;
    border-radius: 2px;
    background-color: rgba(255,255,255,.5);
    border: none;
    border-bottom: 3px solid transparent;
    width: 90%;
    display: block;
    transition: all .3s;
}

.mp-table-date-picker {
    border-radius: 2px;
    background-color: rgba(255,255,255,.5);
    padding: .56rem 0;
    width: 50%;
    margin: .5rem auto;
}

.mp-table-input:focus {
    outline: none;
    box-shadow: 0 1rem 2rem rgba(0,0,0, .1);
    border-bottom: 3px solid #35cb68;
}

.mp-table-input:focus:invalid {
    border-bottom: 3px solid #fb5050;
}

.mp-table-input::-webkit-input-placeholder {
    color: #e2e1e1;
}
/* FORM INPUT END */

/* FORM DROP DOWN START */
.mp-table-drop-down {
    border-radius: 2px;
    padding: .5rem;
    margin: .5rem auto;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    min-width: 80px;
    background: url(data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgZGF0YS1uYW1lPSJMYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0Ljk1IDEwIj48ZGVmcz48c3R5bGU+LmNscy0xe2ZpbGw6I2ZmZjt9LmNscy0ye2ZpbGw6IzQ0NDt9PC9zdHlsZT48L2RlZnM+PHRpdGxlPmFycm93czwvdGl0bGU+PHJlY3QgY2xhc3M9ImNscy0xIiB3aWR0aD0iNC45NSIgaGVpZ2h0PSIxMCIvPjxwb2x5Z29uIGNsYXNzPSJjbHMtMiIgcG9pbnRzPSIxLjQxIDQuNjcgMi40OCAzLjE4IDMuNTQgNC42NyAxLjQxIDQuNjciLz48cG9seWdvbiBjbGFzcz0iY2xzLTIiIHBvaW50cz0iMy41NCA1LjMzIDIuNDggNi44MiAxLjQxIDUuMzMgMy41NCA1LjMzIi8+PC9zdmc+) 100% 30% no-repeat rgba(255,255,255,.5);
}

.mp-table-drop-down:focus {
    outline: none;
}

.mp-table-drop-down::-ms-expand {
    /* IE 10*/
    display: none;
}
/* FORM DROP DOWN END */

.mp-table-select-box {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.mp-table-select-box-input {
    margin-left: 5px !important;
}

.mp-table-result-count {
    padding: 5px !important;
}

.mp-table-pagination-btn {
    align-self: stretch;
    margin: .5rem;
    transition: all .2s;
}

.mp-table-pagination-btn:hover:enabled {
    transform: scale(1.1);
}

.mp-table-pagination-text {
    align-self: center;
    margin: .5rem;
}

.mp-table-pagination-drop-down {
    margin: .5rem;
}




