.conn__devices {
  display: inline-block;
  width: 100%;
  margin-bottom: 0;
}

.searchbar__cont, .resultitems__cont {
  padding: 15px 0;
}

.resultitems__count {
  margin-bottom: 30px;
}

.token__row {
  margin-bottom: 20px;
  border-bottom: 1px solid #ddd;
  padding-bottom: 15px;
  margin-left: 10px;
  margin-right: 10px;
  border: 1px dashed #ccc;
  position: relative;
}

.token__row .serial {
  background-color: #333;
  color: #fff;
  top: 5px;
  left: 5px;
  position: absolute;
  font-size: 10px;
}

.resultitem {
  background: #fff;
  padding: 10px;
  border-radius: 2px;
  color: #333;
  margin-bottom: 40px;
  box-shadow: 0 0 2px rgba(0,0,0,0.3);
}

.resultitem__group {
  margin: 25px 0;
}

.resultitem__group.ptoken {
    margin-bottom: 0;
}

.matched__string {
  background: #FFFF99;
}

.recentsearches__cont {
  padding: 30px;
  padding-top: 20px;
}

.recentsearches {
  background: #fff;
  padding: 15px;
  margin-right: 20px;
  margin-top: 25px;
  border-radius: 2px;
  color: #333;
  box-shadow: 0 0 2px rgba(0,0,0,0.3);
}

.recentsearches__item {
  cursor: pointer;
  color: #428bca;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 13px;
}

.recentsearches__item:hover {
  text-decoration: underline;
}

.resultitem div {
  word-break: break-all;
}

.resultitems__purchase_token .token__text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.resultitems__purchase_token p {
  margin-bottom: 5px;
}

.resultitems__purchase_token_error_icon {
  color: red;
  margin-top: 5px;
}

.resultitems__warning_icon {
  color: orange;
  margin-top: 5px;
}

.resultitems__warning {
  margin-top: 5px;
  padding: 4px 5px;
  word-break: break-word;
}

.resultitems__purchase_token_ok_icon {
  color: green;
  margin-top: 5px;
}

.resultitem__purchasetokens_title {
  margin: 10px;
  font-size: 17px;
}

.copy__button, .history__btn {
  padding: 3px;
  line-height: 1;
  margin-right: 10px;
  margin-top: 10px;
}

.make__moderator {
  cursor: pointer;
  margin-right: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
  color: #ff4444;
}

.make__admin {
  color: #00C851;
}

.make__superadmin {
  color: #ff4444;
}

.make__moderator:hover {
  text-decoration: underline;
}

.search__result_provider_info {
  margin-bottom: 0;
}

@media only screen and (max-width: 991px) {

  .resultitem {
      margin-bottom: 10px;
  }

  .search__button {
    width: 100%;
    margin-top: 10px;
  }

  .recentsearches__cont {
    padding: 0;
  }

  .recentsearches {
    margin-right: 0;
  }
}

.copy-user-precalc-file-id-btn {
  margin-left: 5px;
}

.user-precalc-file-id {
  font-size: 14px;
  color: #097233;
  font-weight: 500;
}

.user-precalc-file-id-main {
  display: flex;
  flex-direction: row;
}

.premium__activatedby {
  /* background: #FFFF99; */
  padding: 10px;
  margin-left: -25px;
  margin-bottom: 10px;
}

.delete-user-total-records {
  margin-top: 20px;
}

.delete-user-total-records-result {
  margin-top: 20px;
}

.btn-delete-user-logs {
  margin-top: 15px;
}

.user-log-delete-success {
  color: darkgreen;
}

.user-log-delete-error {
  color: red;
}

.user-log-delete-table-td {
  padding: 7px !important;
}

.user-log-delete-table {
  border-spacing: 0;
  border: 1px solid #ededed;
  margin-bottom: 0 !important;
  font-size: 14px;
  background: #e2eae59c;
}

.user-log-delete-table>thead>tr>th {
  border-bottom: none;
  border-top: none;
  text-align: center;
  font-weight: 300;
  font-size: 16px;
  background: #0D7233;
  color: #f5f5f5;
  justify-content: center;
}

.download-user-data-container {
  padding: 3%;
}

.download-user-data-view {
  display: flex;
  justify-content: space-around;
}

.download-user-data-button {
  float: right;
}

.purchase__item {
  margin: 10px 0;
}