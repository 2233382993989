.color-picker-main {
    position: absolute;
    z-index: 2;
}

.color-picker-swatch {
    padding: 5px;
    background: #fff;
    border-radius: 1px;
    box-shadow: 0 0 0 1px rgba(0,0,0,.1);
    display: inline-block;
    cursor: pointer;
}

.color-picker-color-box {
    width: 50px;
    height: 20px;
    border-radius: 2px;
    border: 1px solid;
}

.color-picker-cover {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
}

.color-picker-color-box-main {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.color-picker-color-box-text {
    font-size: 14px;
    font-weight: 300;
}

.color-picker-upper-case-hex{
    text-transform: uppercase;
}