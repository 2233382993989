.apple__receipt_table {
  margin-top: 30px;
  background: lightyellow;
}

.sh_input_fields {
  font-size: 12px;
  margin-bottom: 20px;
}


.google__purchase__entry {
  background: #fff;
  padding: 20px;
  margin: 20px 0;
  border: 1px solid #ddd;
}

.google__purchase__entry p strong {
  min-width: 200px;
  display: inline-block;
}

.google__purchase__entry .datetime {
  display: inline-block;
}

.subscription_history_response .sidebar__nav_more {
  color: #286190!important;
  margin-left: -10px;
  font-weight: bold;
  text-decoration: underline;
}