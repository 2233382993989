.datetime {
  margin-bottom: 5px;
}

.datetime p {
  margin-bottom: 0;
}

.widget__image_uploader input, .widget__image_uploader p {
    display: inline-block;
}

.json__tree_toggler + ul {
    margin-bottom: 20px !important;
    padding: 10px !important;
}

.upload-container {
    background: white;
    border-radius: 5px;
    border: 2px dashed rgb(0, 135, 247);
    border-image: none;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    vertical-align: middle;
    line-height: 500px;
}

.uploaded-file {
    flex-basis: 15%;
    margin: 5px;
    padding: 2px;
    border: 1px solid green;
    text-align: center;
    max-width: 15%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.app-modal {
    margin-top: 6vh;
}

.app-modal-confirm {
    margin-top: 6vh;
}

.max-files-upload-error {
    margin-left: 15px;
    font-size: 18px;
    color: red;
}