.sidebar {
  background: #111;
  display: none;
}

.sidebar * {
  user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -o-user-select: none;
}

.mp-side-bar {
  overflow-y: scroll;
}

.sidebar__nav {
  list-style: none;
  background: #222;
  margin-bottom: 1px;
  padding: 0 !important;
}

.sidebar__nav li a {
  color: #ccc;
  width: 100%;
  display: inline-block;
  padding: 3px 10px;
  text-decoration: none !important;
  font-size: 90%;
  background: #555;
  padding-left: 40px;
}

.sidebar__nav li a.active, .sidebar__nav li:hover a {
  background-color: #ccc;
  color: #111;
}

.sidebar__nav__icon {
  margin-right: 15px;
}

.sidebar__nav_title {
  color: #ddd;
  margin-top: 0;
  display: block;
  margin-left: 10px;
  font-size: 13px;
}

.sidebar__nav_more {
  color: #fff !important;
  margin-left: 10px;
  background: transparent !important;
  cursor: pointer;
  padding: 6px 10px;
}

.sidebar__nav_more .glyphicon {
  top: 3px;
  right: 5px;
  float: right;
  font-size: 8px;
}

.sidebar__nav_more .fa {
  margin-right: 15px;
  font-size: 18px;
}

@media only screen and (min-width: 991px) {
  .sidebar {
    display: block;
    height: 100%;
    width: 20%;
    position: fixed;
    top: 0;
    padding-top: 52px;
  }

  .has-alert .sidebar {
    padding-top: 80px;
  }
}

@media only screen and (min-width: 768px) {
  .sidebar {
    display: block;
    height: 100%;
    width: 22%;
    position: fixed;
    top: 0;
    padding-top: 52px;
    padding-bottom: 50px;
  }

  .has-alert .sidebar {
    padding-top: 80px;
  }
}
