.json-diff-main__tool-box {
    font-size: 1.4rem;
    padding: .8rem .5rem;
    position: fixed;
    right: 2rem;
    top: 7rem;
    width: 25%;
    display: flex;
    justify-content: space-evenly;
    align-items: flex-start;
    z-index: 50;
    background-color: #eee;
    border-radius: .5rem;
    box-shadow: 0 .3rem 1rem rgba(0,0,0, .8);
}