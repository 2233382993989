.image-upload-main {
    margin-right: 5rem;
}

.image-upload-main .row {
    margin-right: 0;
    margin-left: 0;
}

.image-upload-tools-main {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

.image-upload-tools-resize-range {
    margin-bottom: 5px;
}