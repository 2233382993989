.quran-quotes-table-main {
    box-shadow: 0 0 2px rgba(0,0,0,.3);
    margin-bottom: 0;
    max-height: 600px;
    overflow-y: scroll;
}

.quran-quotes-table {
    border-spacing: 0;
    border: 1px solid #ededed;
    margin-bottom: 0 !important;
    min-height: 475px;
    font-size: 14px;
}

.quran-quotes-table>thead>tr>th {
    border-bottom: none;
    border-top: none;
    text-align: center;
    font-weight: 300;
    font-size: 18px;
    background: #0D7233;
    color: #f5f5f5;
    padding: 10px;
}

.quran-quotes-table>tbody>tr>td {
    font-weight: 300;
    font-size: 16px;
    padding: 5px 10px !important;
    vertical-align: middle;
}

.quran-quotes-table-main-tools {
    display: flex;
    justify-content: flex-end;
    padding: 5px;
}

.quran-quotes-table-main-tools > * {
    margin-left: 2px;
}

.quran-quote-row-error {
    background: #ecc4c4 !important;
}

.quran-quote-row-deletable {
    background: #c1a740 !important;
}

.quran-quotes-table-main-reference {
    display: flex;
    padding: 5px;
    justify-content: space-evenly;
}

.quran-quotes-table-main-reference > * {
    margin-left: 5px;
}

.quran-quotes-table-ref-color {
    display: flex;
    align-items: center;
}

.quran-quotes-table-ref-color > * {
    margin-left: 5px;
}

.quran-quotes-table-ref-color-1 {
    border: 1px solid;
    width: 25px;
    height: 25px;
    background-color: #ecc4c4;
}

.quran-quotes-table-ref-color-2 {
    border: 1px solid;
    width: 25px;
    height: 25px;
    background-color: #c1a740;
}