.table-toolbar {
    margin-bottom: 8px;
}

.toolbar-button {
    padding: 8px 16px;
    margin-right: 8px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    width: 100px;
}

.toolbar-button.delete {
    background-color: red;
}

.toolbar-button.delete.redis {
    width: 200px;
}

.toolbar-button.prodsync {
    background-color: rgb(18, 254, 6);
    width: auto !important;
}

.toolbar-button.manifest {
    background-color: rgb(6, 184, 254);
    width: auto !important;
}

.toolbar-button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}