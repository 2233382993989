.modal-dialog {
    width: 70%;
}

.json-file-updater-main {
    padding: 0 15px;
}

.json-file-updater-json-view {
    max-height: 600px !important;
    padding: 0;
}

.json-file-updater-diff-view::-webkit-scrollbar-track {
    border: 1px solid #000;
    padding: 2px 0;
    background-color: #404040;
}

.json-file-updater-diff-view::-webkit-scrollbar{
    width: 10px;
}

.json-file-updater-diff-view::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #337ab7;
    border: 1px solid #000;
}

.json-file-updater-diff-view::-webkit-scrollbar-thumb:hover {
    border-radius: 15px;
    background-color: #337ab7;
}

.json-file-updater-diff-view {
    max-height: 400px;
    overflow-y: auto;
}

.json-file-updater-main .variable-row .copy-to-clipboard-container,
.json-file-updater-main .variable-row .click-to-remove {
    /*display: none !important;*/
}
