.promo_code_table_wrapper {
  background: #fff;
  margin-top: 20px;
  box-shadow: 0 0 2px rgba(0,0,0,.3);
  margin-bottom: 20px;
}

.promo_code_table_wrapper .table thead {
  background: #0D7233;
  color: #f5f5f5;
}

.promo__redeemed_row {
  background: #f1f8e9;
}

.promo__filter {
  margin-top: 15px;
}

.promo__count {
  background: #333;
  color: #fff;
  margin-bottom: 0;
  padding: 10px;
}

.truncated_col_el {
  width: 100px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
}

/* Generate Promo Code Page */
.generate__promo_code_wrapper .control-label {
  margin-top: 10px;
}

.generate__promo_code_ta_wrapper {
  margin-top: 33px;
}

.generate__promo_code_ta_wrapper textarea {
  min-height: 370px;
  font-size: 12px;
}

.generate__promo_btn {
  margin-top: 20px;
}

.promo__download_btn {
  margin-top: 20px;
  margin-right: 10px;
}

.promo__action__buttons_div {
  display: inline-block;
  margin-bottom: 10px;
}

.promo__release_error {
  margin-bottom: 0;
}

.promo__code_error_alert {
  margin-top: 20px;
}

.promo_generation_form {
  margin-bottom: 20px;
}

.promo__search_btn {
  margin-top: 10px;
}

.promo__code__detail {
  background: #fff;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 2px;
}

.promo__code__detail p strong {
  min-width: 180px;
  display: inline-block;
}

.promo__code_recent_search {
  background: #fff;
  border: 1px solid #ddd;
  padding: 10px;
  margin-top: 10px;
  border-radius: 2px;
}

.promo__code_recent_search a {
  display: block;
}

.promo__search_input {
  margin-top: 5px;
  margin-bottom: 5px;
}

.listpromocodes__wrapper {
  padding: 10px 0;
  margin-top: 10px;
}

.gift__instance_wrapper {
  margin-top: 20px;
  margin-left: -15px;
  margin-right: 15px;
}

.gift__instance_wrapper table {
  width: 100%;
  font-size: 12px;
}

.gift__instance_wrapper table th {
  padding: 5px;
}

.gift__instance_wrapper table thead {
  background: #000;
  color: #fff;
  width: 100%;
}

.gift__instance_wrapper table thead th {
  text-align: center;
}

.gift__instance_wrapper table tbody .datetime_col {
  min-width: 70px !important;
}

.gift__instance_wrapper table tbody td {
  padding: 3px !important;
}

.gift__instance_wrapper table tbody td.state {
  color: #fff;
  text-align: center;
}

.gift__instance_wrapper table tbody td.edit_col {
  cursor: pointer;
}

.gift__instance_wrapper table tbody td.state span {
  padding: 5px;
  width: 100%;
  display: block;
  border-radius: 5px;
  font-size: 12px;
  font-family: monospace;
}

.completed.state span {
  background: #097233;
}

.failed.state span {
  background: #DC3545;
}

.processing.state span {
  background: #343A40;
}

.giftinstance__editor_modal .modal-dialog {
  width: 800px;
  margin-top: 75px;
  word-break: break-all;
}

.giftinstance__editor_modal .modal-body {
  padding-bottom: 0;
}

.giftinstance__editor_modal .general_info p strong {
  min-width: 150px;
  display: inline-block;
  text-align: right;
  margin-right: 30px;
}

.giftinstance__editor_modal .general_info .row div {
  margin-bottom: 10px;
}

.giftinstance__editor_modal .general_info ol {
  display: inline-block;
  margin-left: 0;
  padding-left: 0;
}

.giftinstance__editor_modal .datetime {
  margin-bottom: 0;
  display: inline-block;
}

.giftinstance__editor_modal .retry__btn {
  margin-right: 10px;
}

.giftinstance__editor_modal .jsontree {
  padding-top: 10px;
  margin-top: 20px;
  background: #333;
  margin-bottom: 0;
}

.state__history_row {
  background: #f5f5f5;
  margin: 5px 0;
  padding: 5px;
  border-radius: 3px;
  border: 1px solid #ddd;
  background: #27A744;
  color: #fff;
}

.state__history_row.error {
  background: #DC3445;
  color: #fff;
}

.promocodes__btn_container {
  margin-bottom: 20px;
}

.test_purchase {
  background: #e25562;
  color: #f5f5f5;
}

.color_box {
  width: 10px;
  height: 10px;
  display: inline-block;
  margin-right: 10px;
  background: #e25562 !important;
}

.compact_col {
  word-break: break-all;
  width: 100px;
}

.gift__instance_wrapper table tbody td {
  margin: 0;
  min-width: 30px !important;
  max-width: 150px !important;
}

.payment_method_icon {
  font-size: 30px;
  color: #e25562;
}

.paypal_color {
  color: #00a6e4;
}

.stripe_color {
  color: #FFBD57;
}

.gift__instance_wrapper table tbody tr:hover {
  background: #ddd;
}

.gift__instance_wrapper table tbody tr:hover td {
  border-color: #ddd;
}