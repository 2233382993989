.gcs-file-versions-table-main {
    overflow-y: auto;
    max-height: 400px;
}

.gcs-file-versions-table-main thead th {
    position: sticky;
    top: 0;
}

.gcs-file-versions-table-main  {
    border-collapse: collapse;
    width: 100%;
}

.gcs-file-versions-table-main th, td {
    padding: 8px 16px;
}

.gcs-file-versions-table-main th {
    background: #097233;
    z-index: 5;
}

.gcs-file-versions-table-main tr>td {
    vertical-align: middle !important;
}

.gcs-file-version-list-tools {
    display: flex;
    flex-direction: row;
    align-items: baseline;
}

.gcs-file-list-manual-update {
    margin-top: 40px;
}

.gcs-file-version-diff-tools {
    margin: 5px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.gcs-file-version-diff-warning {
    color: red;
    margin-top: 10px;
}

.gcs-file-valid-data {
    color: darkgreen;
    font-size: 14px;
}

.gcs-file-invalid_data {
    color: red;
    font-size: 14px;
}
.gcs-file-row-index {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.gcs-file-row-index-number {
    margin: auto;
    font-size: 20px;
    font-weight: 100;
}

.gcs-file-versions-table-main::-webkit-scrollbar-track,
.gcs-file-diff-view-main::-webkit-scrollbar-track {
    border: 1px solid #000;
    padding: 2px 0;
    background-color: #404040;
}

.gcs-file-versions-table-main::-webkit-scrollbar,
.gcs-file-diff-view-main::-webkit-scrollbar{
    width: 10px;
}

.gcs-file-versions-table-main::-webkit-scrollbar-thumb,
.gcs-file-diff-view-main::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #337ab7;
    border: 1px solid #000;
}

.gcs-file-versions-table-main::-webkit-scrollbar-thumb:hover,
.gcs-file-diff-view-main::-webkit-scrollbar-thumb:hover {
    border-radius: 15px;
    background-color: #337ab7;
}

.gcs-file-diff-view-main {
    max-height: 400px;
    overflow-y: auto;
}



