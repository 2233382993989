.timeline_promo_preview_main {
    background: #fff;
    padding: 10px;
    margin-top: 15px;
    -webkit-box-shadow: 0 0 2px #ddd;
    box-shadow: 0 0 2px #ddd;
}

.timeline_promo_preview {
    position: relative;
}

.tp-action-btn, .tp-close-btn {
    position: absolute;
}

.tp-action-btn {
    bottom: 5px;
    right: 5px;
    padding: 5px;
    border-radius: 3px;
    font-size: 18px;
    font-family: 'open sans',arial,sans-serif;
}

.tp-close-btn {
    top: 5px;
    right: 15px;
    font-size: 24px;
}

@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    src: local('Open Sans'), local('OpenSans'), url('http://themes.googleusercontent.com/static/fonts/opensans/v5/cJZKeOuBrn4kERxqtaUH3T8E0i7KZn-EPnyo3HZu7kw.woff') format('woff');
}

.time-line-promo-table td {
    vertical-align: unset !important;
}

.timeline-promo-date {
    max-width: 100px;
}

.timeline-promos-delete-btn {
    margin: 5px !important;
}

.timeline-promo-item-title {
    padding-left: 10px !important;
}
