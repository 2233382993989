.qp_filter_bar {
  margin-top: 20px;
}

.qp_filter_bar .language_picker {
  padding-left: 0;
}

.qp_result_header_row {
  background: #000;
  color: #fff;
  margin-top: 5px;
}

.qp_table_title {
  padding: 0;
  margin-top: 20px;
}

.qp_result_row {
  background: #fff;
  padding: 10px 0;
  border-bottom: 1px solid #ddd;
}

.qp_sura_ids {
  display: block;
  clear: both;
  margin-top: 10px;
  word-break: break-word;
}

.qp_result_row [class*="col-xs"] {
  padding: 5px 20px;
}

.qp_api_sts {
  margin: 30px -15px 0 -15px;
}

.qp_serial, .qp_name {
  font-size: 22px;
}

.is_pinned {
  background: lightyellow;
}

.is_deleted {
  background: #ddd;
  color: #999;
}

.inactive_pinned {
  font-size: 14px;
  color: brown;
}

.inactive_pinned.scheduled {
  color: green;
}

.currently_pinned {
  font-size: 14px;
  color: green;
}

.is_deleted_msg {
  font-size: 14px;
  color: red;
}

.qp_result_row code {
  background: #333;
  color: #fff;
}

.quranplaylist__edit_modal {
  margin-top: 25px;
}

.edit_playlist_icon {
  cursor: pointer;
}

.qp_action_btn {
  margin-top: 20px;
  margin-right: 10px;
}

.qp_bulk_upload .upload-container {
  line-height: 100px;
}

.qp_bulk_upload .upload-container input {
  outline: none !important;
}

.qp_bulk_upload .alert {
  padding: 10px;
  margin-bottom: 10px;
}

.qp_bulk_upload .uploaded-file {
  flex-basis: 80%;
  max-width: 80%;
}

.qp_bulk_upload .uploaded-file > div {
  display: none !important;
}

.qp_tobe_pushed code {
  margin-right: 5px;
  background: #333;
  color: #fff;
}

.qp_tobe_pushed_panel_body {
  word-break: break-all;
}

.qp_tobe_pushed_panel_body p {
  margin-bottom: 0;
}

.sura-v2-table>tbody>tr>td {
  padding: 5px !important;
}

.sura-v2-table .badge {
  margin: 1px;
}

.sura-v2-table>thead>tr {
  background-color: #d2b54e;
}
