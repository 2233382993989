.basiclayout__unauthenticaed_message {
  padding: 15px;
}

.basiclayout__unauthenticaed_message a {
  color: inherit;
}

.basiclayout__content {
  padding-top: 55px;
}

.has-alert {
  margin-top: 33px;
}

.permission_spinner {
  font-size: 200px;
  margin-top: 250px;
  color: #c5c5c5;
}

.basiclayout__row {
  min-height: 100vh;
}

@media only screen and (min-width: 768px) {
  .basiclayout__row {
    margin-right: 10px;
  }
}

@media only screen and (max-width: 767px) {
  .basiclayout__row {
    margin-left: 10px;
    margin-right: 10px;
  }

  .basiclayout__content {
    padding-top: 0;
  }
}
