.moderators__table,
.add__moderator {
  margin-top: 20px;
}

.moderators__table table {
  background: #fff;
}

.moderators__table .checkbox {
  margin: 0;
}

.moderators__table .title,
.add__moderator_title {
  margin-bottom: 20px;
  font-weight: 700;
}

.table__header {
  background: #fff;
}

.table__header th {
  border: none !important;
  font-size: 12px;
  color: #555;
}

.table__header tr:hover {
  background: inherit !important;
}

.invalid__mod {
  background: #ffebee;
  color: #555;
}

.invalid__mod td {
  border-right: 1px solid #ccc !important;
  border-left: 1px solid #ccc !important;
}

.add__moderator_btn {
  margin-top: 10px;
}

.add__alertemail_btn {
  border-radius: 0;
}

.add__moderator_form,
.add__alertemail_form {
  background: #f7f7f7;
  padding: 15px;
  border-radius: 2px;
  color: #555;
  box-shadow: 0 0 2px rgba(0,0,0,.3);
}

.add__alertemail_form {
  margin-top: 61px;
  background: #fff;
  margin-bottom: 20px;
}

.alertemail__input.form-control {
  font-weight: normal !important;
  border-radius: 0 !important;
  width: 70%;
}

.revoke__btn {
  cursor: pointer;
  color: #337ab7;
}

.revoke__btn:hover {
  text-decoration: underline;
}

[class*="role__"] {
  background: #33b5e5;
  color: #fff;
}

.role__111111 {
  background: #ff4444;
  color: #fff;
}

.role__011111 {
  background: #00C851;
  color: #fff;
}

.edit__roles_modal {
  margin-top: 30px;
}

.name_col {
  width: 170px;
}

.permission__btns {
  margin-bottom: 10px;
}

.newly__changed {
  background: #f4ff81;
}
