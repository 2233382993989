.table td, .table th {
  width: 60px;
}

.daily-deen-table-ref-entry {
  display: flex;
  align-items: center;
}

.daily-deen-table-ref-today-entry {
  border: 1px solid;
  width: 25px;
  height: 25px;
  background-color: lightgreen;
}

.daily-deen-table-ref-old-entry {
  border: 1px solid;
  width: 25px;
  height: 25px;
  background-color: lightgray;
}

.daily-deen-table-ref-default-entry {
  border: 1px solid;
  width: 25px;
  height: 25px;
  background-color: lightblue;
}

.daily-deen-table-ref-preview-entry {
  border: 1px solid;
  width: 25px;
  height: 25px;
  background-color: lightpink;
}
.daily-deen-preview-popup {
  position: fixed;
  justify-content: center;
  align-items: center;
  display: flex;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(255, 255, 255, 0.9);
}

.daily-deen-preview-popup .toptext{
  position: absolute;
  top: 0;
  left: 0;
  margin-top: 5px;
  margin-left: 5px;
}

.daily-deen-preview-popup .bottomtext{
  position: absolute;
  bottom: 0;
  left: 0;
  margin-bottom: 45px;
  margin-left: 15px;
}