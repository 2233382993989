.excel-upload-error-container {
    margin-left: 15px;
    margin-right: 15px;
}

.excel-upload-error-container h4 {
    color: red;
}


#excel-upload-data-list {
    margin-top: 10px;
    max-height: 800px;
    overflow: scroll;
}
