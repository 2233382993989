.email__dashboard {
  padding-top: 10px;
}

.email__dashboard .send__button {
  margin-right: 10px;
}

.email__dashboard .send__button {
  margin-top: 20px;
}

.email__dashboard .react-toggle {
  margin-top: 5px;
}

.email__dashboard .control-label {
  margin-top: 20px;
  margin-bottom: 10px;
}

.email__dashboard .list-group-item-heading p {
  display: inline;
  font-weight: 500;
  font-size: 13px;
}

.email__dashboard .list-group-item {
  outline: none !important;
}

.email__dashboard .list-group-item.inactive:hover {
  background: #ddd;
  cursor: pointer;
}

.email__dashboard .list-group-item-text {
  font-size: 85%;
  color: #666;
}

.email__dashboard .mp_control_label_status_icon {
  margin-top: 4px;
}

.email__dashboard .field_info {
  font-size: 12px;
  background: lightgoldenrodyellow;
  padding: 12px;
}

.email__dashboard hr {
  margin: 30px 0;
}

.email__dashboard .created_at {
  font-size: 10px;
}

.email__dashboard .toggle_history_btn {
  padding: 10px;
  font-size: 12px;
  letter-spacing: 1.5;
  font-weight: 500;
  color: #3367d6;
  cursor: pointer;
}

.email__dashboard .toggle_history_btn:hover {
  background: #ddd;
}

.email__dashboard .history__panel {
  right: 0;
  /* position: fixed;
  width: 35%; */
  min-height: 195vh;
  padding: 10px;
  border-left: 1px solid #ddd;
}

.email__dashboard .history__panel.false {
  border: none !important;
}

.email__dashboard .history__list_item {
  padding: 10px 20px;
  margin-left: 10px;
  margin-right: 10px;
}

.email__dashboard .history__list_item p {
  margin-bottom: 0;
  font-size: 85%;
}

.email__dashboard .history__list_item p.head {
  margin-bottom: 30px;
}

.email__dashboard .history__list_item p strong {
  width: 150px;
  display: inline-block;
}

.email__dashboard .history__title {
  margin-left: 10px;
}

.email__dashboard-error-text {
  color: #a94442;
}