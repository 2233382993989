.qalbox-short-create-btn {
    display: inline-block;
    padding: 10px 20px;
    background-color: green;
    color: white;
    text-decoration: none;
    border-radius: 4px;
}

.qalbox-short-top-col {
    padding-top: 20px;
}