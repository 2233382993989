.redis-note {
    color: #564249;
    background-color: #ffc0cbbf;
    border-color: #ff000085
}

.redis-info-highlight-row {
    background: #0d72331f !important;
    font-weight: 500;
}

.redis-info-highlight-key {
    border-right: 1px solid #cec9c9 !important;
}

.redis-main-table {
    background: #9dada32b;
    margin-bottom: 5vh;
    max-width: 600px;
}

.redis-main-table-cell {
    padding: 6px 5px !important;
    vertical-align: center !important;
}

.redis-table-header {
    margin: 0;
    padding: 10px;
    background: #0d7233;
    color: white;
}