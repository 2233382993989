.last-update-time {
    color: #2f6da4;;
}

.hajj-and-umrah-table.table>tbody>tr>td{
    vertical-align: middle !important;
}

.hajj-and-umrah-list-item-title {
    padding-left: 10px !important;
}
.hajj-and-umrah-list-item-date {
    padding: 0 10px !important;
}

.upload-tools {
    display: flex;
    flex-direction: row;
    margin: 5px 0;
    justify-content: space-evenly;
}