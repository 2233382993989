.settings-ts-note-collapse {
    background-color: #ffb1b17a;
    margin-top: 10px;
}

.settings-ts-manual-location-note-header {
    color: #000000b3;
}

.settings-check-box-option-warning {
    color: red;
}

.settings-ts-location-manual-warning {
    color: red;
    margin-top: 10px;
}