.prayer__requests_col {
  padding-left: 0;
  padding-right: 10px;
}

.filter__cont {
  padding: 9px 14px;
  margin-top: 22px;
  margin-bottom: 17px;
  background-color: #e8f5e9;
  border: 1px solid #e1e1e8;
  border-radius: 4px;
}

.filter__cont .col-md-2,
.filter__cont .col-md-3 {
  width: auto;
}

.filter__drop, .filter__label {
  margin-top: 10px;
  display: block;
}

.pr__wrapper {
  margin-top: 10px;
}

.pr__card {
  margin-bottom: 20px;
}

.pr__card_inner {
  background-color: #fff;
  border: 1px solid #e1e1e8;
  border-radius: 2px;
  padding: 5px;
  /* min-height: 215px;
  max-height: 215px; */
}

.pr__card_meta, .pr__card_counts {
  font-size: 80%;
  color: #4F8F63 !important;
  margin-bottom: 15px;
}

.pr__card_counts {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.pr__card_meta, .pr__card_meta a {
  color: #888 !important;
}

.pr__card_meta p {
  margin-bottom: 0;
  display: inline-block;
}

.pr__card_meta_top {
  width: 100%;
}

.pr__card_text {
  /* min-height: 60px;
  max-height: 60px;
  overflow-y: scroll; */
  margin-bottom: 15px;
  line-height: 1.3;
  word-wrap: break-word;
}

.pr__card_counts span.count {
  margin-right: 10px;
}

.pr__card_counts span.count.reported {
  color: #ff4444;
}

.blockedby {
  color: #777;
}

.pr__card_block_btn.pull-left {
  width: 49%;
  outline: none !important;
}

.pr__card_block_btn.pull-right {
  width: 49%;
  outline: none !important;
}

.pr__card_reset_btn {
  margin-top: 10px;
  margin-top: 5px;
  display: initial;
  outline: none !important;
}

.pagniation {
  margin-bottom: 20px;
}

.pagniation__icon {
  font-size: 20px;
  margin-left: 10px;
  cursor: pointer;
}

.blocked__header {
  display: none;
  background: #ef5350;
  color: #fff;
  margin: -5px;
  padding: 2px 5px;
  margin-bottom: 5px;
}

.blocked .blocked__header {
  display: block;
}

.blocked .pr__card_inner {
  /* min-height: 240px;
  max-height: 240px; */
}

.pr__card .address {
  width: 50%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: right;
}

.pr__loading_text {
  font-size: 15px;
  padding-top: 10px;
  padding-bottom: 10px;
}
