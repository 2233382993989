.content_editor_title {
  margin-bottom:40px;
  margin-top: 20px;
}

.content_editor_submit_btn {
  margin-top: 20px;
}

.summary__textarea {
  height: 100px !important;
}

.content__preview_div {
  background: #fff;
  padding: 10px;
  margin-top: 15px;
  box-shadow: 0 0 2px #ddd;
}

.content__preview_div_source {
  color: red;
}

.summary__col, .title__col {
  width: 250px;
}

.title__col {
  font-weight: bold;
  width: 400px;
}

.summary__col {
  color: #666;
  font-size: 90%;
  width: 200px;
}

.source__col {
  color: red;
}

.type__col {
  width: 100px;
}

.curated__col {
  width: 100px;
}

.on__browser__iframe {
  border: none;
  height: 500px;
}

.content__list_table {
  display: block;
  overflow-y: auto;
  table-layout: fixed;
}

.content__list_table_row:hover {
  background: rgba(76, 175, 80, 0.2);
}

.content__list_table_row.pinned {
  background: #ffd27fb0;
}

.content__list_table_row.featured_item {
  background-color: rgba(148, 187, 199, 0.64);
}

.content__list_table_row.hidden_item {
  background-color: #989191a3;
  color: #675b5b82;
}

.content__list_table_row.hidden_item.a {
  color: #337ab79e;
}

.source__col_disabled {
  color: #ff000070;
}

.content__list_table_wrapper {
  background: #fff;
  box-shadow: 0 0 2px rgba(0,0,0,.3);
  margin-bottom: 20px;
}

.content__list_table_wrapper .table thead {
  background: #0D7233;
  color: #f5f5f5;
}

.content__list_table_wrapper .table thead th {
  padding: 10px;
}

/* Tobe removed when we will allow mods to toggle show ads */
.show__ads_btn_wrapper {
  background: #fff;
  padding: 3px;
  opacity: 0.5;
  display: inline-block;
  cursor: not-allowed;
}

.show__ads_btn_wrapper > div {
  pointer-events: none;
}
/* tobe removed ends */

.show__ads_label {
  display: block;
}

.content__list_filterbar {
  background-color: #333;
  color: #fff;
  padding: 10px 0;
  margin: 0;
  border-bottom: 1px solid #ddd;
}

.content__list_filterbar select {
  margin-left: 5px;
  margin-right: 10px;
  color: #000;
}

.content__list_loading_text {
  margin-top: 20px;
}

.content__list_filterbar input {
  color: #000;
  border-radius: 5px;
  width: 300px;
  outline: none !important;
  border: none;
  padding: 5px;
}

.react-tags {
  position: relative;
  padding: 6px 0 0 6px;
  border: 1px solid #D1D1D1;
  border-radius: 1px;

  /* shared font styles */
  font-size: 1em;
  line-height: 1.2;

  /* clicking anywhere will focus the input */
  cursor: text;
}

.react-tags.is-focused {
  border-color: #B1B1B1;
}

.react-tags__selected {
  display: inline;
}

.react-tags__selected-tag {
  display: inline-block;
  box-sizing: border-box;
  margin: 0 6px 6px 0;
  padding: 6px 8px;
  border: 1px solid #D1D1D1;
  border-radius: 2px;
  background: #F1F1F1;

  /* match the font styles */
  font-size: inherit;
  line-height: inherit;
}

.react-tags__selected-tag:after {
  content: '\2715';
  color: #AAA;
  margin-left: 8px;
}

.react-tags__selected-tag:hover,
.react-tags__selected-tag:focus {
  border-color: #B1B1B1;
}

.react-tags__search {
  display: inline-block;

  /* match tag layout */
  padding: 7px 2px;
  margin-bottom: 6px;

  /* prevent autoresize overflowing the container */
  max-width: 100%;
}

@media screen and (min-width: 30em) {

  .react-tags__search {
    /* this will become the offsetParent for suggestions */
    position: relative;
  }

}

.react-tags__search input {
  /* prevent autoresize overflowing the container */
  max-width: 100%;

  /* remove styles and layout from this element */
  margin: 0;
  padding: 0;
  border: 0;
  outline: none;

  /* match the font styles */
  font-size: inherit;
  line-height: inherit;
}

.react-tags__search input::-ms-clear {
  display: none;
}

.react-tags__suggestions {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
}

@media screen and (min-width: 30em) {

  .react-tags__suggestions {
    width: 240px;
  }

}

.react-tags__suggestions ul {
  margin: 4px -1px;
  padding: 0;
  list-style: none;
  background: white;
  border: 1px solid #D1D1D1;
  border-radius: 2px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
}

.react-tags__suggestions li {
  border-bottom: 1px solid #ddd;
  padding: 6px 8px;
}

.react-tags__suggestions li mark {
  text-decoration: underline;
  background: none;
  font-weight: 600;
}

.react-tags__suggestions li:hover {
  cursor: pointer;
  background: #eee;
}

.react-tags__suggestions li.is-active {
  background: #b7cfe0;
}

.react-tags__suggestions li.is-disabled {
  opacity: 0.5;
  cursor: auto;
}

.max-w-100 {
  max-width: 100px !important;
}

.max-w-150 {
  max-width: 150px !important;
}

.max-w-200 {
  max-width: 200px !important;
}

.min-w-50 {
  min-width: 50px !important;
}

.min-w-70 {
  min-width: 70px !important;
}

.min-w-100 {
  min-width: 100px !important;
}

.fw-b {
  font-weight: bold;
}

.margin-all3 {
  margin: 0 3px 3px 3px !important;
}

.meta-info{
  border: 1px solid #00000038;
  padding: 10px 7px 0;
}

.newHashTag {
  z-index: 9;
}

.countries-dropdown {
  z-index: 10;
}

.color-codes-list,
.color-codes-list-v2 {
  /*float: right;*/
  padding-left: 0;
  display: flex;
  list-style: none;
  flex-direction: row;
  justify-content: flex-start;
}

.color-codes-list-v2 {
  padding-left: 10px;
  margin: 0;
}


.color-codes-list-item {
  font-weight: 700;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-right: 1.5em;
}

.hidden_color_code {
  margin-left: 5px;
  width: 25px;
  height: 25px;
  background-color: #989191a3;
}

.pinned_color_code {
  margin-left: 5px;
  width: 25px;
  height: 25px;
  background: #ffd27fb0;
}

.featured_color_code{
  margin-left: 5px;
  width: 25px;
  height: 25px;
  background-color: rgba(148, 187, 199, 0.64);
}

.css-42igfv {
  position: fixed !important;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.content-v2-index {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-width: 60px;
  height: 100%;
}

.content-v2-index-number {
  margin: auto;
  font-size: xx-large;
  font-weight: 100;
}

.content-v2-index-items {
  margin: auto;
}

.content-v2-index-item {
  margin-top: 5px;
  margin-left: 5px;
}

.featured_color_code_icon {
  margin-left: 6px;
  margin-top: 5px;
}

.pinned_color_code_icon {
  margin-left: 7px;
  margin-top: 5px;
}

.hidden_color_code_icon {
  margin-left: 6px;
  margin-top: 5px;
}

.content-v2-type-icon  {
  margin-left: 8px;
}

.content-v2-excel-upload-errors-container {
 margin-left: 15px;
 margin-right: 15px;
}

.content-v2-excel-upload-errors-container h4 {
 color: red;
}

.content-v2-excel-upload-success-container h4 {
 color: #097233;
}

#content-v2-excel-upload-error-list {
  max-height: 800px;
  overflow: scroll;
}

#content-v2-image-upload-url-list {
  max-height: 500px;
  overflow-y: scroll;
}

#content-v2-image-upload-url-list::-webkit-scrollbar-track {
  border: 1px solid #000;
  padding: 2px 0;
  background-color: #404040;
}

#content-v2-image-upload-url-list::-webkit-scrollbar {
  width: 10px;
}

#content-v2-image-upload-url-list::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #097233;
  border: 1px solid #000;
}

#content-v2-image-upload-url-list::-webkit-scrollbar-thumb:hover {
  border-radius: 15px;
  background-color: #09a243;
}

.excel-upload-note-header {
  font-size: 15px;
  color: black;
}

.upload-note-collapse{
  margin: 10px 0;
  /*min-width: 800px;*/
}

.scroll {
  z-index: 9999;
  cursor: pointer;
  width: 60px;
  height: 60px;
  position: fixed;
  bottom: 40px;
  right: -80px;
  border-radius: 100%;
  background-image: radial-gradient( circle farthest-corner at 10% 20%,  rgba(90,92,106,1) 0%, rgba(32,45,58,1) 81.3% );
  color: #fff;
  font-size: 36px;
  font-weight: bold;
  text-align: center;
  box-shadow: 0 0 5px 0 #888;
  transition: 300ms;
}

.scroll i {
  text-shadow: 0 0 2px #fff;
}

.scroll:hover i {
  animation-name: rotate;
  animation-duration: 300ms;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}

@keyframes rotate {
  from {margin-top: 15px}
  to {margin-top: 5px}
}

.visible {
  right: 30px;
  transition: all 400ms;
  transform: rotate(360deg)
}

.back-to-top-text {
  font-size: 8px;
  margin: -14px;
}

.bulk-delete-list-modal-body {
  max-height: 700px;
  overflow: scroll;
}

.bulk-delete-list-modal-body::-webkit-scrollbar {
  -webkit-appearance: none;
}

.bulk-delete-list-modal-body::-webkit-scrollbar:vertical {
  width: 12px;
}

.bulk-delete-list-modal-body::-webkit-scrollbar:horizontal {
  height: 12px;
}

.bulk-delete-list-modal-body::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, .5);
  border-radius: 10px;
  border: 2px solid #ffffff;
}

.bulk-delete-list-modal-body::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: #ffffff;
}

.word-wrap-text {
  word-wrap: break-word;
}

.mp-content-v2-table-hashtag {
  margin-right: 3px;
  padding: 2px 5px;
  border-radius: 3px;
  font-size: 12px;
  font-weight: bold;
  margin-top: 2px;
}

.mp-content-v2-table-series-id {
  background-color: #e69ac0;
  color: #22543d;
  margin-right: 3px;
  padding: 3px 6px;
  border-radius: 3px;
  font-size: 12px;
  font-weight: bold;
  margin-top: 2px;
}

.mp-content-v2-table-hashtag-list {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  flex-wrap: wrap;
  align-content: space-evenly;
}

.mp-content-v2-table-td-pt {
  padding: 10px 2px !important;
}

.mp-content-v2-table-content-id,
.mp-content-v2-table-content-title  {
  display: flex !important;
  flex-direction: column-reverse;
  justify-content: center;
  align-items: center;
}

.mp-content-v2-table-hover-btn {
  margin-bottom: 5px;
  transition: all .2s;
}

.mp-content-v2-table-hover-btn:hover {
  transform: scale(1.2);
}

.mp-content-v2-table-tools {
  padding: 5px 0;
  display: flex;
  align-items: center;
}

.mp-content-v2-preview-table td {
  text-align: center;
  vertical-align: unset !important;
}

.mp-content-v2-preview-table th {
  text-align: center;
  background: #0D7233;
  color: #f5f5f5
}

.content-v2-edit-link {
  padding: 5px;
  margin: 5px;
  border: 1px solid;
}

.content-v2-edit-links {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.content-v2-edit-panel {
  border: none;
  margin: 0;
  box-shadow: none;
  display: flex;
  justify-content: center;
  background-color: unset;
}

.content-v2-edit-panel .panel {
  background-color: white;
}

.hash-tags-delete-warning {
  color: red;
  font-weight: 400;
}

/* Trending Hashtags Styles */
.trending-hashtags-container {
  border: 1px solid #0b2e13;
  padding: 10px;
  margin-bottom: 10px;
  max-height: 400px;
  min-height: 400px;
}

.trending-hashtags-table-container {
  max-height: 300px;
  min-height: 300px;
  overflow: auto;
}

.trending-hashtags-table {
  margin-bottom: 25px;
}

.trending-hashtags-tools-container {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.trending-hashtags-table-row {
  margin: 0 2px;
}

.trending-hashtags-error-msg {
  display: block;
  margin: 5px 0 10px 5px;
  color: red;
}

.trending-hashtags-limit-warn-msg {
  padding: 5px !important;
}
