.mobilemenu {
  background: #19212b;
  border-radius: 0;
}

.mobilemenu li:hover {
  background-color: rgba(255,255,255,.08);
  color: #fff;
}

.mobilemenu .navbar-brand {
  font-weight: bold;
  font-size: 14px;
  color: #fff;
  padding: 5px 10px;
}

.mobile_brand_name {
  font-size: 18px;
  color: #097233;
  text-decoration: none;
}

.mobile_console_name {
  font-size: 18px;
}

.mobile_user_name {
  font-size: 14px;
  color: white;
  text-decoration: none;
  font-weight: 300;
}

.mobile_header {
  display: flex;
}

.mobile_header_name {
  margin-left: 8px;
  display: flex;
  flex-direction: column;
}

.mobile_header_options {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.mobile_console_name_with_env {
  display: flex;
}

@media only screen and (min-width: 768px) {
  .mobilemenu {
    display: none;
  }
}

@media only screen and (max-width: 768px) {
  .mobilemenu {
    margin-bottom: 0;
  }
}
