.permission_spinner {
    font-size: 200px;
    margin-top: 250px;
    color: #c5c5c5;
}

@media only screen and (max-width: 768px) {
    .permission_spinner {
        font-size: 170px;
    }
}
